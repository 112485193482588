import React, { useState } from 'react';
import './CitibotChat.scss';

const CitibotChat = () => {
    const [iframeVisible, setIframeVisible] = useState(false);

    const handleChatToggle = () => {
        setIframeVisible(!iframeVisible);
    };

    return (
        <div
            id="root"
            style={{
                position: 'fixed',
                bottom: '30px',
                right: '50px',
                cursor: 'pointer',
                zIndex: 999999,
            }}
        >
            <img
                src={'/img/citibot.gif'}
                alt="Citibot webchat"
                style={{ width: '100px', height: '100px' }}
                onClick={handleChatToggle}
            />
            {iframeVisible && (
                <iframe
                    title="Citibot Chat"
                    id="citibot"
                    name="citibot"
                    src={'https://webchat.citibot.io?org_id=87'}
                    style={{
                        display: iframeVisible ? 'block' : 'none',
                        opacity: iframeVisible ? '1' : '0',
                        boxShadow: '0 0 5px grey',
                        borderRadius: '10px',
                        border: 'none',
                        zIndex: 2147483000,
                        position: 'fixed',
                        bottom: '17vh',
                        right: '20px',
                        width: '450px',
                        height: 'calc(100vh - 150px)',
                        transition: 'opacity 0.3s ease 0s',
                    }}
                    className={iframeVisible ? 'citibot_out' : 'citibot_in'}
                />
            )}
        </div>
    );
};

export default CitibotChat;
